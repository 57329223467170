import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'
import store from '@/store'

export default function useStructures() {
  const { errorToast, successToast } = useAlertNotification()
  const structure = ref({structure:{abonnements:[]}})
  const loader = ref(false)
  const structureProcess = ref(false)
  const structureSuccess = ref(false)
  const structures = ref([])
  const statistics = ref([])
  const serials = ref([])
  const errors = ref([])
  const hider = ref(false)

  var series = []
  var reports = []
  const chartReportOptions = ref({})
  const goalOverviewRadialBar = ref({})
  const goalOverviewRadialBarNonDelivre = ref({})
  // Liste des structures
  const getStructures = async () => {
    loader.value = true
    await axiosClient.get('/structures').then(response => {
      if (response.data.success === true) {
        loader.value = false
        structures.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Liste des structures
  const checkStructure = async id => {
    loader.value = true
    await axiosClient.get(`/structures/check/${id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        console.log(response.data.data)
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une structure
  const getStructure = async id => {
    loader.value = true

    const response = await axiosClient.get(`/users/${id}`)
    structure.value = response.data.data
    loader.value = false
  }
  // Ajouter une structure
  const createStructure = async data => {
    errors.value = ''
    structureProcess.value = true
    await axiosClient.post('/structures', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          structureSuccess.value = true
          structureProcess.value = false
          getStructures()
        }
      })
      .catch(error => {
        structureProcess.value = false
        structureSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Ajouter une structure
  const resetToken = async (id, data) => {
    errors.value = ''
    structureProcess.value = true
    await axiosClient.patch(`/reset-token/${id}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          structureSuccess.value = true
          structureProcess.value = false
        }
      })
      .catch(error => {
        structureProcess.value = false
        structureSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une structure
  const updateStructure = async data => {
    errors.value = ''
    structureProcess.value = true
    await axiosClient.patch(`/structures/${data.id}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          structureSuccess.value = true
          structureProcess.value = false
          getStructures()
        }
      })
      .catch(error => {
        structureProcess.value = false
        structureSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }


  // Statistique de l'admin
  const getStatistics = async () => {
    loader.value = true
    await axiosClient.get('/get-statistics').then(response => {
      if (response.data.success === true) {
        loader.value = false
        statistics.value = response.data.data
        serials.value = response.data.data.series

        chartReportOptions.value = {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: ['#f6f6f6'],
            colors: ['#ff9f43'],
          },
          colors: ['#ff9f43'],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },

          xaxis: {
            categories: response.data.data.graph.categories,
          },
        }

        goalOverviewRadialBar.value = {
          series: [statistics.value.cev_delivre_percent],
          chartOptions: {
            chart: {
              sparkline: {
                enabled: true,
              },
              dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1,
              },
            },
            colors: ['#51e5a8'],
            plotOptions: {
              radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                  size: '77%',
                },
                track: {
                  background: '#ebe9f1',
                  strokeWidth: '50%',
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    color: '#5e5873',
                    fontSize: '2.86rem',
                    fontWeight: '600',
                  },
                },
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#28c76f'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            stroke: {
              lineCap: 'round',
            },
            grid: {
              padding: {
                bottom: 30,
              },
            },
          },
        },
          goalOverviewRadialBarNonDelivre.value = {
            series: [statistics.value.cev_non_delivre_percent],
            chartOptions: {
              chart: {
                sparkline: {
                  enabled: true,
                },
                dropShadow: {
                  enabled: true,
                  blur: 3,
                  left: 1,
                  top: 1,
                  opacity: 0.1,
                },
              },
              colors: ['#f00'],
              plotOptions: {
                radialBar: {
                  offsetY: -10,
                  startAngle: -150,
                  endAngle: 150,
                  hollow: {
                    size: '77%',
                  },
                  track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%',
                  },
                  dataLabels: {
                    name: {
                      show: false,
                    },
                    value: {
                      color: '#5e5873',
                      fontSize: '2.86rem',
                      fontWeight: '600',
                    },
                  },
                },
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: 'horizontal',
                  shadeIntensity: 0.5,
                  gradientToColors: ['#28c76f'],
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 100],
                },
              },
              stroke: {
                lineCap: 'round',
              },
              grid: {
                padding: {
                  bottom: 30,
                },
              },
            },
          },
          series.push(
            response.data.data.series
          )
        reports.push(
          response.data.data.graph
        )
      }
    })
      .catch(() => {
        loader.value = false
      })
  }
  const getStatisticsByStructure = async () => {
    loader.value = true
    await axiosClient.get('/get-statistics-by-structure').then(response => {
      if (response.data.success === true) {
        loader.value = false
        statistics.value = response.data.data
        serials.value = response.data.data.series

        chartReportOptions.value = {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: ['#f6f6f6'],
            colors: ['#ff9f43'],
          },
          colors: ['#ff9f43'],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },

          xaxis: {
            categories: response.data.data.graph.categories,
          },
        }

        goalOverviewRadialBar.value = {
          series: [statistics.value.cev_delivre_percent],
          chartOptions: {
            chart: {
              sparkline: {
                enabled: true,
              },
              dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1,
              },
            },
            colors: ['#51e5a8'],
            plotOptions: {
              radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                  size: '77%',
                },
                track: {
                  background: '#ebe9f1',
                  strokeWidth: '50%',
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    color: '#5e5873',
                    fontSize: '2.86rem',
                    fontWeight: '600',
                  },
                },
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#28c76f'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            stroke: {
              lineCap: 'round',
            },
            grid: {
              padding: {
                bottom: 30,
              },
            },
          },
        },
          goalOverviewRadialBarNonDelivre.value = {
            series: [statistics.value.cev_non_delivre_percent],
            chartOptions: {
              chart: {
                sparkline: {
                  enabled: true,
                },
                dropShadow: {
                  enabled: true,
                  blur: 3,
                  left: 1,
                  top: 1,
                  opacity: 0.1,
                },
              },
              colors: ['#f00'],
              plotOptions: {
                radialBar: {
                  offsetY: -10,
                  startAngle: -150,
                  endAngle: 150,
                  hollow: {
                    size: '77%',
                  },
                  track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%',
                  },
                  dataLabels: {
                    name: {
                      show: false,
                    },
                    value: {
                      color: '#5e5873',
                      fontSize: '2.86rem',
                      fontWeight: '600',
                    },
                  },
                },
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: 'horizontal',
                  shadeIntensity: 0.5,
                  gradientToColors: ['#28c76f'],
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 100],
                },
              },
              stroke: {
                lineCap: 'round',
              },
              grid: {
                padding: {
                  bottom: 30,
                },
              },
            },
          },



          series.push(
            response.data.data.series
          )

        reports.push(
          response.data.data.graph
        )

      }
    })
      .catch(() => {
        loader.value = false
      })
  }
  // Liste des structures
  const filterStatistics = async (data) => {
    loader.value = true
    hider.value=true
   
    goalOverviewRadialBarNonDelivre.value=null
    await axiosClient.post('/filter-statistics', data).then(response => {
      if (response.data.success === true) {
        loader.value = false
        statistics.value = response.data.data
        serials.value = response.data.data.series
        
        chartReportOptions.value = {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: ['#f6f6f6'],
            colors: ['#ff9f43'],
          },
          colors: ['#ff9f43'],
          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },

          xaxis: {
            categories: response.data.data.graph.categories,
          },
        }

        goalOverviewRadialBar.value = {
          series: [statistics.value.cev_delivre_percent],
          chartOptions: {
            chart: {
              sparkline: {
                enabled: true,
              },
              dropShadow: {
                enabled: true,
                blur: 3,
                left: 1,
                top: 1,
                opacity: 0.1,
              },
            },
            colors: ['#51e5a8'],
            plotOptions: {
              radialBar: {
                offsetY: -10,
                startAngle: -150,
                endAngle: 150,
                hollow: {
                  size: '77%',
                },
                track: {
                  background: '#ebe9f1',
                  strokeWidth: '50%',
                },
                dataLabels: {
                  name: {
                    show: false,
                  },
                  value: {
                    color: '#5e5873',
                    fontSize: '2.86rem',
                    fontWeight: '600',
                  },
                },
              },
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 0.5,
                gradientToColors: ['#28c76f'],
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100],
              },
            },
            stroke: {
              lineCap: 'round',
            },
            grid: {
              padding: {
                bottom: 30,
              },
            },
          },
        },
          goalOverviewRadialBarNonDelivre.value = {
            series: [statistics.value.cev_non_delivre_percent],
            chartOptions: {
              chart: {
                sparkline: {
                  enabled: true,
                },
                dropShadow: {
                  enabled: true,
                  blur: 3,
                  left: 1,
                  top: 1,
                  opacity: 0.1,
                },
              },
              colors: ['#f00'],
              plotOptions: {
                radialBar: {
                  offsetY: -10,
                  startAngle: -150,
                  endAngle: 150,
                  hollow: {
                    size: '77%',
                  },
                  track: {
                    background: '#ebe9f1',
                    strokeWidth: '50%',
                  },
                  dataLabels: {
                    name: {
                      show: false,
                    },
                    value: {
                      color: '#5e5873',
                      fontSize: '2.86rem',
                      fontWeight: '600',
                    },
                  },
                },
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shade: 'dark',
                  type: 'horizontal',
                  shadeIntensity: 0.5,
                  gradientToColors: ['#28c76f'],
                  inverseColors: true,
                  opacityFrom: 1,
                  opacityTo: 1,
                  stops: [0, 100],
                },
              },
              stroke: {
                lineCap: 'round',
              },
              grid: {
                padding: {
                  bottom: 30,
                },
              },
            },
          },

























          series.push(
            response.data.data.series
          )

        reports.push(
          response.data.data.graph
        )

      } else {
        statistics.value = []
        chartReportOptions={}
        goalOverviewRadialBar.value=null
        series=[]
        reports={}
        hider.value=true

      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  return {
    errors,
    structure,
    structures,
    getStructures,
    structureProcess,
    createStructure,
    updateStructure,
    getStructure,
    loader,
    resetToken,
    checkStructure,
    structureSuccess,
    statistics,
    getStatistics,
    getStatisticsByStructure,
    serials,
    series,
    reports,
    chartReportOptions,
    goalOverviewRadialBar,
    goalOverviewRadialBarNonDelivre,
    filterStatistics,
    hider,
  }
}
